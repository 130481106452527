import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import {is_object, is_empty,get_att_data} from "@/components/base/utils";

const component_map = {
        h1: () => import("@/components/base/el/BaseH1"),
        h2: () => import("@/components/base/el/BaseH2"),
        h3: () => import("@/components/base/el/BaseH3"),
        h4: () => import("@/components/base/el/BaseH4"),
        h5: () => import("@/components/base/el/BaseH5"),
        p: () => import("@/components/base/el/BaseP"),
        a: () => import("@/components/base/el/BaseA"),
        b: () => import("@/components/base/el/BaseB"),
        del: () => import("@/components/base/el/BaseDel"),
        img: () => import("@/components/base/el/BaseImg"),
        span: () => import("@/components/base/el/BaseSpan"),
        button: () => import("@/components/base/el/BaseButton"),
        li: () => import("@/components/base/el/BaseLi"),
        small: () => import("@/components/base/el/BaseSmall"),
        special_div: () => import("@/components/base/el/BaseDiv"),
        special_section: () => import("@/components/base/el/BaseSection"),
    }

export default {
    name: "BasePagination",
    mixins: [BaseRenderMixin],
    props: {
        component_data: Object,
        page_count: [Number],
        pageRange: {
            type: Number,
            default: 3
        },
    },
    data() {
        return {
            pageActive: ''
        }
    },
    render(createElement) {
        // if (this.is_blog_page && !this.$store.state.fetched_blogs) {
        //     this.$store.state.fetched_blogs = true
        //     this.fetch()
        // }
        return this.make_element_tree(createElement, this.component_data);
    },
    created() {
        let url = new URL(window.parent.location.href)
        let search_params = new URLSearchParams(url.search)
        this.pageActive = search_params.get('page') ? JSON.parse(search_params.get('page')) : 1
        console.log(this.pageActive);
    },
    computed: {
        pages() {
            let marginPages = 1
            let items = {}
            if (this.page_count <= this.pageRange) {
                for (let index = 0; index < this.page_count; index++) {
                    items[index]={
                        'index': index+1,
                        'content': index+1,
                        'selected': index+1 === this.pageActive
                    }
                }
            } else {
                const halfPageRange = Math.floor(this.pageRange / 2)
                let setPageItem = index => {
                    items[index] = {
                        index: index+1,
                        content: index + 1,
                        selected: index === (this.pageActive - 1)
                    }
                }
                let setBreakView = index => {
                    items[index] = { breakView: true }
                }

                // 1st - loop thru low end of margin pages
                for (let i = 0; i < marginPages; i++) {
                    setPageItem(i);
                }

                let selectedRangeLow = 0;
                if (this.pageActive - halfPageRange > 0) {
                    selectedRangeLow = this.pageActive - 1 - halfPageRange;
                }

                let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
                if (selectedRangeHigh >= this.page_count) {
                    selectedRangeHigh = this.page_count - 1;
                    selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
                }
                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.page_count - 1; i++) {
                    setPageItem(i);
                }

                // Check if there is breakView
                if (selectedRangeLow > marginPages) {
                    setBreakView(selectedRangeLow - 1)
                }
                if (selectedRangeHigh + 1 < this.page_count - marginPages) {
                    setBreakView(selectedRangeHigh + 1)
                }

                // 3rd - loop thru high end of margin pages
                for (let i = this.page_count - 1; i >= this.page_count - marginPages; i--) {
                    setPageItem(i);
                }
            }
            console.log(items);
            return items
        }
    },
    methods: {
        is_blog_page() {
            if (process.env.NODE_ENV === "development") return true
            let path = window.parent.location.pathname.split("/")
            return path[path.length - 1] === 'blogs'
        },
        focus_out() {
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            if (!this.page_count) return ['']
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data, context, context)
            attrs = this.get_attrs(element_data, context)
            children = this.get_children(element_data, createElement, context)
            style = this.get_style(element_data)

            if (attrs) data.attrs = {...attrs, ...data.attrs}
            if (style) data.style = {...style, ...data.style}

            if (props) data.props = props
            if (attrs) data.attrs = attrs
            if (style) data.style = style

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data, context) {
            let el = element_data.tag
            let props = null
            if (context && context.pagination_item) {
                el = element_data.tag
            } else if (is_object(element_data.attr) && !is_empty(element_data.attr) && ('deepsel-pagination-next' in element_data.attr || 'deepsel-pagination-last' in element_data.attr)){
                el = element_data.tag
            }else if (Object.keys(component_map).includes(element_data.tag)) {

                el = component_map[element_data.tag]
                props = {component_data: element_data}

            }
            return [el, props]
        },
        get_attrs(element_data, context) {
            let attrs = {}
            if (element_data.attr) {
                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    if (key === 'type' && element_data.tag === 'button') element_data.attr[key] = 'button'
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }

                if (element_data.tag === "script") {
                    attrs.type = "text/javascript";
                    attrs.defer = false;
                    attrs.async = false;
                }

                let att_data = get_att_data(element_data, this.$store.state.common_data)
                if (att_data) {4
                    for (let key in att_data) {
                        attrs[key] = att_data[key]
                    }
                }
                if (context && context.pagination_item && context.pagination_item.selected) {
                    attrs.class = attrs.class ? attrs.class + ' active' : 'active'
                }
                
                if (!this.$store.state.edit_mode) {
                    let url = new URL(window.parent.location.href)
                    let search_params = new URLSearchParams(url.search)
                    if (context && context.pagination_item) {
                        if (context.pagination_item.index+1) {                        
                            search_params.set('page', context.pagination_item.index)
                        }

                        attrs.href = url.origin + url.pathname+ '?'+search_params.toString()
                    }
                    if (is_object(element_data.attr) && !is_empty(element_data.attr) && ('deepsel-pagination-next' in element_data.attr)){
                        if (this.pageActive === this.page_count) search_params.set('page', this.pageActive)
                        else search_params.set('page', this.pageActive + 1)
                        attrs.href = url.origin + url.pathname+ '?'+search_params.toString()
                    }
                    if (is_object(element_data.attr) && !is_empty(element_data.attr) && ('deepsel-pagination-last' in element_data.attr)){
                        search_params.set('page', this.page_count)
                        attrs.href = url.origin + url.pathname+ '?'+search_params.toString()
                    }
                }


                this.replace_origin_urls(attrs, element_data)

                return attrs
            }
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = element_data.attr.style
                if (style.backgroundImage) {
                    if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                } else if (element_data.tag === "link" || element_data.tag === "a" && attrs.href) {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
        get_children(element_data, createElement, context) {
            let children = []
            let single_pagination_item = false
            let text_data = null
            if (context && context.pagination_item) {
                if (context.pagination_item.breakView) text_data = '...'
                else text_data = context.pagination_item.content

            }

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {
                        if (is_object(item.attr) && !is_empty(item.attr) && 'deepsel-pagination-item' in item.attr) {
                            if (single_pagination_item) continue
                            single_pagination_item = true

                            for (const [e] of Object.entries(this.pages)) {
                                children.push(this.make_element_tree(createElement, item, {}, {pagination_item: this.pages[e]}))    
                            }

                        } else {
                            children.push(this.make_element_tree(createElement, item));
                        }
                    } else if (item.node === "text") children.push(text_data ? text_data : item.text)
                }
            }

            return children
        },
    },
}